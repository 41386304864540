import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { BaseComponent } from 'src/app/modules/core/components/base/base.component';
import { DayTaskDto } from 'src/app/modules/core/models/day-tasks/dayTaskDto';
import { UpdateDayTaskStatusRequest } from 'src/app/modules/core/models/day-tasks/updateDayTaskStatusRequest';
import { BroadcasterService } from 'src/app/modules/core/services/broadcaster.service';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import {
  compareDates,
  formatToLocalDateString,
} from 'src/app/modules/core/utils/date-options';
import { DayTasksService } from 'src/app/modules/day-tasks/services/day-tasks.service';
import { ManageDayTaskSharedComponent } from '../manage-day-task-shared/manage-day-task-shared.component';

@Component({
  selector: 'app-day-tasks-list-shared',
  templateUrl: './day-tasks-list-shared.component.html',
  styleUrls: ['./day-tasks-list-shared.component.scss'],
  providers: [DialogService],
})
export class DayTasksListSharedComponent
  extends BaseComponent
  implements OnInit, OnDestroy,OnChanges
{
  @Input() currentDate: Date = new Date();
  @Input() isPage: boolean = true;

  isDayTasksDataLoading: boolean = false;
  isDayTasksCheckLoading: boolean = false;
  selectedTasks: DayTaskDto[] = [];
  tasksList: DayTaskDto[] = [];
  manageTaskDialog: DynamicDialogRef | undefined;

  constructor(
    broadcastService: BroadcasterService,
    authService: AuthenticationService,
    private dayTasksService: DayTasksService,
    private toasterService: ToastService,
    public dialogService: DialogService
  ) {
    super(broadcastService, authService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentDate']) {
      this.getData();
    }
  }

  checkBoxChange(event: any, taskId: string) {
    this.isDayTasksCheckLoading=true
    var task = this.tasksList.find((t) => t.id == taskId)!;
    this.dayTasksService
      .updateTaskStatus(taskId, {
        status: !task.completed,
      } as UpdateDayTaskStatusRequest)
      .subscribe(
        () => {
          this.isDayTasksCheckLoading=false
          this.toasterService.successToast('Status updated!');
          this.tasksList = this.tasksList.map((t) => {
            if (t.id == taskId) {
              return {
                ...t,
                completed: !task.completed,
                completedBy: !task.completed
                  ? {
                      appUserId: this.authUser.appUserId!,
                      fullName: this.authUser.fullName,
                      profilePictureUrl: this.authUser.profilePicUrl,
                    }
                  : undefined,
              };
            }
            return t;
          });
          if (!task.completed) {
            this.selectedTasks = [
              ...this.selectedTasks,
              {
                ...task,
                completed: true,
                completedBy: {
                  appUserId: this.authUser.appUserId!,
                  fullName: this.authUser.fullName,
                  profilePictureUrl: this.authUser.profilePicUrl,
                },
              },
            ];
          } else {
            this.selectedTasks = this.selectedTasks.filter(
              (t) => t.id != taskId
            );
          }
        },
        () => {
          this.isDayTasksCheckLoading=false
          if (task.completed) {
            this.selectedTasks = [...this.selectedTasks, task];
          } else {
            this.selectedTasks = this.selectedTasks.filter(
              (t) => t.id != taskId
            );
          }
        }
      );
  }

  getData() {
    this.isDayTasksDataLoading = true;
    this.dayTasksService
      .getDayTasks(formatToLocalDateString(this.currentDate))
      .subscribe(
        (data) => {
          this.isDayTasksDataLoading = false;
          this.tasksList = data;
          this.selectedTasks = this.tasksList.filter((t) => t.completed);
        },
        () => {
          this.isDayTasksDataLoading = false;
        }
      );
  }
  openManageTask(operation: string, task?: DayTaskDto) {
    if (
      operation != 'create' &&
      !this.authService.isAdminsAndManger &&
      task?.createdBy.appUserId != this.authUser.appUserId
    ) {
      return;
    }
    this.manageTaskDialog = this.dialogService.open(
      ManageDayTaskSharedComponent,
      {
        header: operation == 'create' ? 'Create task' : 'Update task',
        styleClass: 'w-12 lg:w-9 h-screen lg:h-9',
        data: {
          task,
        },
      }
    );
    this.manageTaskDialog.onClose.subscribe((res: any) => {
      if (res) {
        if (
          res.operation == 'create' &&
          compareDates(res.date, this.currentDate) == 0
        ) {
          this.tasksList = [res.task, ...this.tasksList];
          if (res.task.completed) {
            this.selectedTasks = [res.task, ...this.selectedTasks];
          }
        } else if (res.operation == 'delete') {
          this.tasksList = this.tasksList.filter((t) => t.id != res.taskId);
        } else if (res.operation == 'update') {
          this.tasksList = this.tasksList.map((t) => {
            if (t.id == res.task.id) {
              return res.task;
            }
            return t;
          });
        }
      }
    });
  }
  override ngOnDestroy(): void {
    if (this.manageTaskDialog) {
      this.manageTaskDialog.close();
    }
    super.ngOnDestroy();
  }
}
